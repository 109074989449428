<template>
  <div class="Developer">
    <developer-header class="header" />
    <transition name="fade" mode="out-in">
      <router-view class="panel" />
    </transition>
  </div>
</template>

<script>
import DeveloperHeader from './DeveloperHeader';

export default {
  components: {
    DeveloperHeader,
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/developer/Developer.scss';
</style>

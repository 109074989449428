<template>
  <div class="header">
    <div class="header-title">{{ title }}</div>
    <div class="header-item right">{{ usersCount }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['usersCount', 'currentDeveloperProject']),
    projectName() {
      if (this.currentDeveloperProject) {
        return this.currentDeveloperProject.projectName;
      }
      return '';
    },
    title() {
      switch (this.$route.name) {
        case 'developerUsersList':
          return 'Developer Users';
        case 'developerUserProjects':
          return 'Developer User Projects';
        case 'developerUserProject':
          return this.projectName;
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
